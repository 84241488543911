import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useRef, useState, useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import axios from '../assets/axios'
import jwt_decode from 'jwt-decode'

const LOGIN_URL = '/users/login'

const Login = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const userRef = useRef();
    const errRef = useRef();
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL, JSON.stringify({ user, pwd }), {headers: { 'Content-Type': 'application/json' }, withCredentials: true });
            const accessToken = response?.data?.accessToken;
            const userDecode = jwt_decode(accessToken);
            const from = location.state?.from?.pathname || `/user/view/${userDecode.user.id}`;
            setAuth({ accessToken });          
            setUser('');
            setPwd('');
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    return (
        <div className='container-fluid pt-5 mb-3' style={{borderRadius: '5px'}}>
            <div className="container">
                <section>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                    <div className="section-title mb-3">
                        <h4 className="m-0 text-uppercase font-weight-bold">Sign In</h4>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="username">Email:</label><br />
                            <input type="text" id="username" ref={userRef} autoComplete="off" onChange={(e) => setUser(e.target.value)} value={user} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password:</label><br />
                            <input type="password" id="password" onChange={(e) => setPwd(e.target.value)} value={pwd} required />
                        </div>
                        <button className="btn btn-primary font-weight-semi-bold px-4" style={{height: '50px'}} >Sign In</button>
                    </form>
                    <p>
                        Need an Account?
                        <span className="line">
                            {/*put router link here*/}
                            <Link className='badge badge-primary text-uppercase font-weight-semi-bold mt-3 mb-3 p-2 ml-2' to="/register"> Sign Up</Link>
                        </span>
                    </p>
                </section>
            </div>
        </div>
    )
}

export default Login