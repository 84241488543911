import { Link, useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import jwtDecode from 'jwt-decode'

import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import { addToCart } from "../../../redux/cartReducer"
import useAuth from '../../../hooks/useAuth'
import Card from "../components/Card"

const VIEW_PRODUCT_URL = '/shop/product/'

const Product = ({PF}) => {
  const { auth } = useAuth();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const axiosPrivate = useAxiosPrivate();
  const [selectedImg, setSelectedImg] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState({});
  const [categories, setCategories] = useState([]);
  const [trending, setTrending] = useState([]);
  const [image, setImage] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let {id} = useParams();

  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    
    axiosPrivate.get(VIEW_PRODUCT_URL + id).then((response) => {
      if(isMounted){ 
        setProduct(response.data.merch);
        setImage(response.data.merch.images.split(','));
        setCategories(response.data.categories);
        setTrending(response.data.trendingMerch);
      }

    }).catch((error) => {
        //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, [id]);

  let newPrice = '';
  if(product?.salePrice>0){
    newPrice = product?.salePrice;
    
  }else{
    newPrice = product?.price;
  }
  
  return (
    <div className="container container-fluid">
      <Link className="badge badge-primary text-uppercase font-weight-semi-bold mt-3 mb-3 p-2 mr-1" to={`/shop`}>Shopfront</Link>
      {categories?.map((item) => (
        <Link key={item.id} className="badge badge-primary text-uppercase font-weight-semi-bold mt-3 mb-3 p-2 mr-1" to={`/shop/products/${item.id}`}>{item.title}</Link>
      ))}
      <div className="mt-3">
        <h2 className="mb-3 text-secondary text-capitalize font-weight-bold">{product?.title}</h2>
      </div>
      <div className="product row">
        <div className="col-lg-2">
          <div className="left">
            <div className="images row">
              { image?.map((img, index)=>(
                <div key={index} className="col-6 mb-2" style={{height: '70px'}}>
                  <img className='img-fluid w-100 h-100' src={ PF + img } alt="" onClick={(e) => setSelectedImg(index)}  style={{objectFit: 'cover', cursor: 'pointer'}}/>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="left">         
            <div className="mainImg">
              <img src={ image[selectedImg] ? PF + image[selectedImg] : PF + image[0] } alt="" />
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="right">
            <span className="price">{newPrice && newPrice.toLocaleString('en')}/-</span>
            <p>{product?.description}</p>
            <div className="quantity">
              Quantity: 
              <button onClick={() => setQuantity((prev) => (prev === 1 ? 1 : prev - 1)) } > - </button>
              {quantity}
              <button onClick={() => setQuantity((prev) => prev + 1)}>+</button>
            </div>
            {product.size &&
              <div className="size">
                Size: <button onClick={() => setQuantity((prev) => (prev === 1 ? 1 : prev - 1)) } > - </button>
                {quantity}
                <button onClick={() => setQuantity((prev) => prev + 1)}>+</button>
              </div>
            }
            {authDecode 
              ? 
                <button className='add' onClick={() => dispatch(addToCart({ id: product.id, title: product.title, desc: product.description, price: newPrice, img: image[0], quantity,}))}>
                  <i className="fa fa-cart-plus" aria-hidden="true" /> ADD TO CART
                </button>
              : 
                <button className='add' onClick={() => navigate('/register')}>
                  <i className="fa fa-cart-plus" aria-hidden="true" /> ADD TO CART
                </button>
            }
            <hr />
          </div>
        </div>
      </div>
      <div className="featuredProducts col-lg-12 mt-5">
        <div className="section-title">
          <h4 className="m-0 text-uppercase font-weight-bold">Trending Products</h4>
        </div>
        <div className="bottom row">
          { trending?.map((item) => <Card PF={PF} item={item} key={item.id}  onClick={() => navigate(`/shop/view/${item.id}`)}/>)}
        </div>
      </div>
    </div>
  );
};

export default Product;
